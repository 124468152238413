.banners {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.banners__item {
  margin: 1rem 2rem;
}