

// Helpers
// ==================================================

.bl     { display: block; }
.in-bl  { display: inline-block; }
.flex   { display: flex; }
.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-space-between {
  justify-content: space-between;
}

.word-break { word-break: break-all; }
.no-break { white-space: nowrap; }
.justify { text-align: justify; }

.flex-equal {
  flex: 1;
}

.p-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.screen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.no-scroll {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.no-events {
  pointer-events: none !important;
}

.hidden {
  display: none;
}

.hide {
  opacity: 0 !important;
  pointer-events: none;
  transition: $transition-opacity;
}

.show {
  opacity: 1 !important;
  pointer-events: all;
  transition: $transition-opacity;
}

.collapse {
  display: block;
  max-height: 0;
  transition: $transition;
  margin: 0 !important;
  padding: 0 !important;

  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  &.show {
    max-height: 10000vh;
    opacity: 1;
    pointer-events: initial;
  }
}

.hidden-md {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.hidden-sm {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.hidden-xs {
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.space-m-all-0 { margin: 0 !important; }
.space-m-all-20 { margin: em(20) !important; }
.space-m-all-30 { margin: em(30) !important; }
.space-m-all-40 { margin: em(40) !important; }
.space-m-all-60 { margin: em(60) !important; }
.space-m-all-80 { margin: em(80) !important; }
.space-m-all-100 { margin: em(100) !important; }
.space-p-all-0 { padding: 0 !important; }
.space-p-all-20 { padding: em(20) !important; }
.space-p-all-30 { padding: em(30) !important; }
.space-p-all-40 { padding: em(40) !important; }
.space-p-all-60 { padding: em(60) !important; }
.space-p-all-80 { padding: em(80) !important; }
.space-p-all-100 { padding: em(100) !important; }

.space-m-t-0 { margin-top: 0 !important; }
.space-m-t-20 { margin-top: em(20) !important; }
.space-m-t-30 { margin-top: em(30) !important; }
.space-m-t-40 { margin-top: em(40) !important; }
.space-m-t-60 { margin-top: em(60) !important; }
.space-m-t-80 { margin-top: em(80) !important; }
.space-m-t-100 { margin-top: em(100) !important; }
.space-m-b-0 { margin-bottom: 0 !important; }
.space-m-b-20 { margin-bottom: em(20) !important; }
.space-m-b-30 { margin-bottom: em(30) !important; }
.space-m-b-40 { margin-bottom: em(40) !important; }
.space-m-b-60 { margin-bottom: em(60) !important; }
.space-m-b-80 { margin-bottom: em(80) !important; }
.space-m-b-100 { margin-bottom: em(100) !important; }
.space-p-t-0 { padding-top: 0 !important; }
.space-p-t-20 { padding-top: em(20) !important; }
.space-p-t-30 { padding-top: em(30) !important; }
.space-p-t-40 { padding-top: em(40) !important; }
.space-p-t-60 { padding-top: em(60) !important; }
.space-p-t-80 { padding-top: em(80) !important; }
.space-p-t-100 { padding-top: em(100) !important; }
.space-p-b-0 { padding-bottom: 0 !important; }
.space-p-b-20 { padding-bottom: em(20) !important; }
.space-p-b-30 { padding-bottom: em(30) !important; }
.space-p-b-40 { padding-bottom: em(40) !important; }
.space-p-b-60 { padding-bottom: em(60) !important; }
.space-p-b-80 { padding-bottom: em(80) !important; }
.space-p-b-100 { padding-bottom: em(100) !important; }

.bg-black {
  background: #000;
  color: $text-color-inverse;
}

.bg-white {
  background: #fff;
}

.bg-grey {
  background: $bg-color;
}

.bg-primary {
  background: $primary-color !important;
  color: $text-color-inverse;
}

// Video
.greyToWhiteFilter{
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'brightness\'><feColorMatrix type=\'matrix\' values=\'1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0\'/></filter></svg>#brightness"); /* Firefox 3.5+ */
    filter: brightness(108.5%); /* Chrome 19+ & Safari 6+ */
}

.roundedBorder {
  border-radius: rem(20);
  border: 1px solid $border-color;
}

.gradientBottom {
  position: relative;
  // gradient
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(450);

    background-image: linear-gradient(to top, $bg-color 0%, rgba(240, 242, 246, 0) 100%);

    z-index: 0;
  }
}

.subpage .teaser__inner,
.subpage .teaser__box {
  display: none;
}