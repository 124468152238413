
// Header
// ==================================================

.header {
  padding: 0 rem(60);

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.header__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: rem(100);
  padding: rem(20) rem(60) rem(20) rem(333);

  background: #fff;

  z-index: 10;

  @include media-breakpoint-up(md) {
    &.nav--scrolled {
      height: rem(70) !important;

      .nav__logo {
        transform: scale(.8);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include media-breakpoint-down(md) {
    .nav__item {
      font-size: rem(14, 18);
    }
  }

  @include media-breakpoint-down(sm) {
    height: rem(50, 14);
  }
}

.header__teaser {
  $w: 1800;
  $h: 700;

  height: 0;
  width: 100%;
  padding-bottom: ($h / $w * 100%);

  @include media-breakpoint-down(sm) {
    padding-bottom: 55%;
  }
}

.subpage .header__teaser {
  padding-bottom: 350px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 250px;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 150px;
  }
}