.partners {
  @extend .list--no-style;
  text-align: center;
}

.partners__item {
  vertical-align: top;
  // width: rem(250);
  text-align: center;
  margin: 0 rem(25) rem(50);

  display: inline-block;

  > * {
    display: block;
  }

  img {
    height: rem(74);
    width: auto;
    margin: auto;
    margin-bottom: rem(22);
  }

  @include media-breakpoint-down(md) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.partners__item--dial-telecom {
  img {
    position: relative;
    left: rem(-10);
  }
}

.partners__item--svet-v-bezpeci {
  img {
    position: relative;
    top: rem(8);

    @include media-breakpoint-down(md) {
      top: auto;
    }
  }
}

.partners__item--bakalari {
  img {
    position: relative;
    left: rem(-18);
  }
}

.partners__label {
  @extend .text--small;
  height: em(55);
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    height: auto;
  }
}

.partners--small {
  .partners__item {
    margin-left: 2rem;
    margin-right: 2rem;
    img {
      height: rem(50);
    }
  }
}