
// Color theme mixin
@mixin textColor($color, $c) {
  .text--#{$color} {
    &, a {
      color: $c;
      &:hover, &:focus {
        color: transparentize( $c, .25);
      }

      &:before, &:after {
        transition: $transition;
      }

      &:before {
        color: $c;
      }

      &:after {
        background: $c;
      }
    }

    &.text--no-hover {
      &, &:hover, &:focus {
        color: $c !important;
      }
    }

    &.text--underline {
      // underline
      &:after {
        background: $c;
      }

      &:hover {
        &:after {
          background: transparentize($c, .25);
        }
      }
    }
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @extend .text--bold;
  text-align: center;
  margin: 1em 0 2em;

  .section > &:first-child,
  .container > &:first-child {
    margin-top: 0;
  }
}

h1, .h1 {
}

h2, .h2 {
}

h3, .h3 {
}

h4, .h4 {
}

h5, .h5 {
}

h6, .h6 {
}

p {
  margin-bottom: em(8, 12);
  &:last-child {
    margin-bottom: 0;
  }
}

.text {
  font-size: $font-size-base;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--normal {
  font-weight: 100;
}

.text--bold, b {
  font-weight: 600;
}

strong {
  font-weight: 600;
}

.text--extra-small {
  font-size: rem(12);
  line-height: 1;
  margin-bottom: em(8, 12);
}

.text--small {
  font-size: rem(13);
}

.text--semi-small {
  font-size: rem(16);
}

.text--semisemi-small {
  display: inline-block;
  font-size: rem(18);
  line-height: 1.25;
}

.text--large {
}

.text--underline {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: em(-8, 13);
    left: 50%;
    transform: translate(-50%, 0);
    height: em(3, 13);
    width: 100%;
    background: $text-color;
  }
}

// Colors
@include textColor('primary', $primary-color);
@include textColor('secondary', $secondary-color);
@include textColor('black', #000);
.text--white {
  &, a {
    color: #fff;
    &:hover, &:focus {
      &, &:before, &:after {
        color: transparentize(#fff, .25);
      }
    }

    &:before, &:after {
      transition: $transition;
    }

    &:before {
      color: #fff;
    }

    &:after {
      color: #fff;
      background: #fff;
    }
  }
}

@include media-breakpoint-down(md) {

  h1, .h1 {
    font-size: rem(38, 18);
  }

  // h2, .h2 {
  //   font-size: 50px;
  // }

  // h3, .h3 {
  //   font-size: 40px;
  // }

  // h4, .h4 {
  //   font-size: 30px;
  // }

}

@include media-breakpoint-down(sm) {

  h1, .h1 {
    font-size: rem(26, 14);
  }

  // h2, .h2 {
  //   font-size: 50px;
  // }

  // h3, .h3 {
  //   font-size: 40px;
  // }

  // h4, .h4 {
  //   font-size: 30px;
  // }

}