.teaser {
  position: relative;
  background-size: cover;
  background-position: center;
  // background-image: url(../images/teaser.jpg);
}

.teaser__inner {
  position: absolute;
  top: 50%;
  left: rem(276);
  transform: translateY(-50%);

  @include media-breakpoint-down(lg) {
    left: rem(100);
    width: 100%;
    padding: 1rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem;
    left: 0;
  }

  @media (max-width: 359px) {
    padding: 1rem;
  }
}

.teaser__title {
  text-align: left;
  margin: 0;
}

.teaser__btn {
  margin-top: em(50);
}

.teaser--kontakt {
  $w: 3840;
  $h: 800;
  width: 100%;
  height: 0;
  padding-bottom: ($h / $w * 100%);

  @include media-breakpoint-down(sm) {
    padding-bottom: 35%;
  }
}