.date {

  @extend .roundedBorder;
  width: rem(80);
  height: rem(80);
  text-align: center;
  padding: rem(16) 0;

  > * {
    display: block;
    line-height: 1;
    margin: 0;
  }
}

.date__day {
  @extend .h3;
  color: $secondary-color;
}

.date__month {
  @extend strong;
  @extend .text--semi-small;
  // margin: rem(2, 16) 0 0;
}

.date__year {
  @extend .date__day;
  font-size: rem(28);
  margin-top: rem(4, 28);
}