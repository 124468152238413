

// Global Variables
// ==================================================

// Colors
$primary-color: #f69d00;
$secondary-color: #17a9e1;
$bg-color: #f0f2f6;
$border-color: #e0e4ec;
$shadow-color: transparentize(#183f64, .85);

$facebook-color: #3b5998;
$google-plus-color: #dc4a38;

$text-color: #001b30;
$text-color-inverse: #ffffff;
$body-color: $text-color;
// End Colors


// Fonts
$root-font-size: 20;

$font-family-base: 'Signika', sans-serif;

$headings-font-family: $font-family-base;
$headings-line-height: 109 / 100;
$headings-font-weight: 600;

$font-weight-normal: 100;
$font-weight-bold: 600;

$line-height-base: 30 / 20;

$h1-font-size: rem(50);
$h2-font-size: rem(40);
$h3-font-size: rem(30);
$h4-font-size: rem(20);
$h5-font-size: rem(15);
$h6-font-size: rem(10);

// End Fonts

$enable-rounded:   false;

$easeInQuad:         cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic:        cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart:        cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint:        cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine:         cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo:         cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc:         cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack:         cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutQuad:        cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic:       cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart:       cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint:       cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine:        cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo:        cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc:        cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack:        cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easeInOutQuad:      cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic:     cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart:     cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint:     cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine:      cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo:      cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc:      cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack:      cubic-bezier(0.680, -0.550, 0.265, 1.550);

// Animations
$transition:         all .3s $easeInOutQuad;
$transition-opacity: opacity .3s $easeInOutQuad;