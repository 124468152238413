.offers {
  @extend .clearfix;
}

.offers__item {

  $w: 960;
  $h: 546;

  position: relative;
  display: block;
  float: left;
  width: 50%;
  padding-bottom: ($h / $w * 50%);
  color: $text-color-inverse;


  background-size: cover;
  background-position: center;

  // borders hack
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 110%;
    height: rem(25);
    background: $text-color-inverse;
    transition: $transition;
  }

  // border top
  &:before {
    top: rem(-2);
  }

  // border bottom
  &:after {
    bottom: rem(-2);
  }

  &:hover {
    &:before,
    &:after {
      height: 0;
    }
  }

  @include media-breakpoint-down(md) {
    float: none;
    margin-left: auto;
    margin-right: auto;

    width: 75%;
    padding-bottom: ($h / $w * 75%);
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-bottom: ($h / $w * 100%);
  }
}

.offers__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;

  p {
    margin-bottom: em(30);
  }

  .more {
    @extend .more--white;
  }

  * {
    color: $text-color-inverse !important;
  }

  @include media-breakpoint-down(sm) {

    br {
      display: none;
    }

    p, .more {
      font-size: rem(12, 14);
    }

    p {
      margin-bottom: .5rem;
    }

    .offers__title {
      margin-bottom: 1rem;
    }
  }
}

.offers__title {
  @extend .text--normal;
  text-align: left;
  margin: 0 0 em(40, 40);
}

.offers__item--left {
  &:before,
  &:after {
    right: 0;
  }

  .offers__text {
    width: rem(400);
    right: rem(134);
  }

  @include media-breakpoint-down(sm) {
    .offers__text {
      width: 90%;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.offers__item--right {
  &:before,
  &:after {
    left: 0;
  }

  .offers__text {
    width: rem(420);
    left: rem(106);
  }

  @include media-breakpoint-down(sm) {
    .offers__text {
      width: 90%;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
}