.news {

}

.news__item {

  &:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    @extend .hide;
    max-height: 0;
    transition: $transition;

    body.newsShown & {
      @extend .show;
      max-height: 100vh;
    }

    body:not(.newsShown) & {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  body:not(.newsShown) &:nth-of-type(3) {
    border-color: transparent;
  }

}

.new {
  position: relative;
  padding: rem(25) rem(121);

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  @include media-breakpoint-down(sm) {
    padding-right: 1rem;
  }
}

.new__date {
  position: absolute;
  left: rem(21);
  top: rem(68);
}

.new__content {
  @extend .box;
  width: 100%;
  padding: rem(40);
  text-align: left;
  margin: 0;
  border: 0;

  .more {
    margin-top: em(25);
    transition: $transition-opacity;
  }

  @include media-breakpoint-down(sm) {
    padding: rem(15, 14);
  }
}

.new__title {
  margin: 0 0 em(33, 30);
  color: $secondary-color;
  text-align: left;
}

.new--collapse {
  cursor: pointer;

  &.active {
    .more {
      opacity: 0;
    }
  }
}

.new__collapse {
  // height: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 0;

  transition: $transition;

  .new--collapse.active & {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
  }
}
