.jumbotron {
  @extend .roundedBorder;
  position: relative;
  border: 0;
  text-align: center;
  background-color: $bg-color;
  padding: em(28) em(135);

  max-width: em(800);
  margin: 0 auto;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    padding: em(30);
  }
}