.boxes {
  display: flex;
  justify-content: center;
  margin: rem(150) 0;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
}

.box {
  position: relative;
  display: block;
  margin: rem(40);
  text-align: center;
  padding: rem(53) rem(30) rem(60);
  width: rem(320);
  
  @extend .roundedBorder;

  transition: $transition;

  &:hover {
    box-shadow: 0 rem(20) rem(75) 0 $shadow-color;
    background: $text-color-inverse;
    border-color: $text-color-inverse;

    .box__img {
      img:last-child {
        opacity: 1;
      }
    }
  }
}

.box__img {
  position: relative;
  height: rem(66);
  margin-bottom: rem(65);
  img {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: $transition-opacity;

    &:last-child {
      opacity: 0;
    }
  }
}

.box--more {
  padding: rem(90) rem(50) rem(130);
  .h4 {
    margin-bottom: em(25, 20);
  }

  .more {
    position: absolute;
    bottom: rem(60);
    left: 50%;
    transform: translateX(-50%);
  }
}

