.more {
  @extend .text--bold;
  display: inline-block;
  color: $primary-color;

  // arrow
  &:after {
    content: '';
    position: relative;
    display: inline-block;
    width: em(14);
    height: em(10);
    background: url(../images/arrow-right.svg);
    background-size: cover;
    margin-left: em(6);
    top: em(-1);
  }
}

.more--white {
  color: #fff;
  &:after {
    background-image: url(../images/arrow-right-white.svg);
  }
}