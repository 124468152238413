
// Footer
// ==================================================

.footer {
  text-align: center;
  padding: rem(75) 0 rem(25);
}

.footer__copyright {
  @extend .text--semi-small;
  p {
    margin: 0;
  }
}