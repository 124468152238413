.list {
}

.list--no-style {
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.list--inline {
li, .list__item {
    display: inline-block;
  }
}

.list--block-inline {
  @include clearfix;
  li, .list__item {
    display: block;
    float: left;
  }
}

.list--horizontal {
  display: flex;
  > * {
    flex: 1;
  }
}

.list--more-space {
  li, .list__item {
    margin-bottom: em(18, 24);
  }
}

.list--custom {
  @extend .list--no-style;

  li {
    padding-left: 1em;
    margin-bottom: .5em;

    .list__symbol {
      position: relative;
      color: $blue;

      &:before {
        content: '';
        position: absolute;
        height: .5em;
        width: .5em;
        left: -1em;
        top: .33em;
        background-color: currentColor;
        color: currentColor;
        border-radius: 50%;
      }
    }
  }
}

.list--counter {
  @extend .list--custom;

  li {
    padding-left: 0;
    margin-bottom: em(80);
    counter-increment: step-counter;
    padding-left: em(150);

    .list__symbol {
      &::before {

        font-size: em(120);
        line-height: 1;

        left: em(-30, 120);
        content: counter(step-counter) '.';
        font-weight: 300;

        height: auto;
        width: auto;
        background: none;

        top: 0;
        transform: translate(-100%, -37%);
      }
    }
  }
}

.list--checkers {
  @extend .list--no-style;
  margin-left: em(34);

  .list__item {
    position: relative;
    margin-bottom: em(50);
    padding-left: em(80);

    &:last-child {
      margin-bottom: 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    // checkbox background
    &:before {
      width: em(40);
      height: em(40);
      border-radius: em(10);
      box-shadow: 0 em(5) em(15) 0 $shadow-color;

      left: em(14);
      top: em(5);
    }

    // check
    &:after {
      width: em(18);
      height: em(14);
      background: url(../images/check.svg);
      background-size: cover;
      background-position: center;

      top: em(25);
      left: em(34);

      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;

      transition: $transition;
    }

    &.disabled {
      &:after {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        transition: $transition;
      }
    }
  }
}

.list__item--no-checkbox {
  &:before,
  &:after {
    display: none !important;
  }
}

.list__item--oneLine {
  padding-top: em(11);
}