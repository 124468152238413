a {

  &, * {
    transition: $transition;
  }

  color: $text-color;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: transparentize($text-color, .25);
  }

  // telephone
  &[href^="tel:"] {
    color: $text-color;
  }
}

.link {
  position: relative;
  transition: $transition;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  .ic {
    position: absolute;
    font-size: 1.2em;
    top: 50% !important;
    left: auto;
    right: auto;
    transform: translate(.5em, -50%);
  }
}

.link--primary {
  @extend strong;
  @extend .text--primary;
}

.link--white {
  @extend .text--white;
}


.link--underline {

  position: relative;
  display: inline-block;
  line-height: 1.2;

  transition: $transition;

  white-space: nowrap;

  // underline
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: currentColor;
    opacity: .25;

    transition: $transition;
  }

  &:hover, &:focus, &:active, &.active {
    &:after {
      opacity: 0;
    }
  }
}

.link--fb {
  @extend .text--bold;
  color: $facebook-color;

  img {
    position: relative;
    top: rem(-2);
    margin-right: .25rem;
  }

  &:hover {
    color: darken($facebook-color, 10%);
  }
}