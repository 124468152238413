

// Buttons
// ==================================================
.btn, button {
  @extend .text;
  display: inline-block;
  font-family: $font-family-base;
  color: $text-color;
  text-align: center;
  white-space: nowrap;

  vertical-align: middle;
  transform-origin: center;

  border: 0;
  box-shadow: 0;
  outline: 0;

  overflow: hidden;

  transition: $transition;
  background: transparent;
  cursor: pointer;
}

.btn {

  @extend .text--bold;
  height: em(60);
  min-width: em(240);
  border-radius: em(30);

  padding: 0 2em;

  background: $primary-color;
  color: $text-color-inverse;

  &:hover, &:active, &.active {
    box-shadow: 0 em(10) em(30) 0 transparentize($primary-color, .5);
  }
}