
// Main navigation
// ==================================================

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $transition;
}

.nav--scrolled {
  box-shadow: 0 0 rem(20) 0 $shadow-color;
}

.nav__logo {
  transform-origin: center;
  img {
    width: rem(90);
    height: rem(54);
  }
}

.nav__list {
  @extend .list--no-style;
  @extend .list--inline;

  @include media-breakpoint-down(sm) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);

    background: $text-color-inverse;
    padding: .5rem;

    height: calc(100vh - #{rem(50, 14)});

    transition: $transition-opacity;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .nav__item {
      font-size: rem(14, 14);
      padding: .25em;

      &:before {
        display: none;
      }
    }
  }

  @media (orientation: landscape) and (max-width: 767px) {
    .nav__item, .nav__item a {
      padding: 0;
    }
  }
}

.nav__item {
  text-align: center;
  margin: .5em em(10);

  a {
    @extend .text--bold;
    display: block;
    padding: em(5);
  }

  &.active, &:hover, &:focus, &:active {
    @extend .text--primary;
  }
}

.nav__item--separated {
  position: relative;
  padding-left: rem(25);
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: rem(80);
    background-image: linear-gradient(to top, #ffffff 0%, #ffffff 15%, #e0e4ec 50%, #ffffff 85%);

    transition: $transition;

    @include media-breakpoint-up(md) {
      .nav--scrolled & {
        height: rem(56);
      }
    }
  }

}

.nav__item--locale {
  a {
    display: inline-block;
    font-weight: 100;

    &.active {
      color: $primary-color;
    }
  }
}

// mobile toggle and hamburger
.nav__toggle {

  display: none;

  @extend .text--semi-small;
  @extend strong;
  font-size: 18px;

  text-transform: uppercase;
  user-select: none;

  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 101;
  padding: 5px 8px 8px 20px;
  line-height: 1;

  background: transparent;
  color: $text-color;

  cursor: pointer;

  .nav__hamburger {
    // mixin vendor/less-burder.less
    @include burger(13px, 2px, 3px, $text-color);
  }

  .nav__text {
    display: inline-block;
  }

  &.is-active {

    .nav__hamburger {

       // mixin vendor/less-burder.less
      @include burger-to-cross(2px, 3px, $text-color);
    }

    + .nav__list {
      @extend .show;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;

    + .nav__list {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.subpage {
  .nav__list:not(.nav__list--homepage) {
    display: block !important;
  }
  .nav__list--homepage {
    display: none;
  }
}
