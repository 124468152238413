
// Functions
// ==================================================
@function vw($x, $screen: 1920px) {
  @return ($x / ($screen * 0.01 )) * 1vw;
}

@function vh($x, $screen: 1080px) {
  @return ($x / ($screen * 0.01 )) * 1vh;
}

@function em($size-in-px, $font-size: $root-font-size) {
  @return ($size-in-px / $font-size) * 1em;
}

@function rem($size-in-px, $font-size: $root-font-size) {
  @return ($size-in-px / $font-size) * 1rem;
}
