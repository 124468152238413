@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.eot?abwiif");
  src: url("../fonts/iconfont.eot?abwiif#iefix") format("embedded-opentype"), url("../fonts/iconfont.ttf?abwiif") format("truetype"), url("../fonts/iconfont.woff?abwiif") format("woff"), url("../fonts/iconfont.svg?abwiif#usm") format("svg");
  font-weight: normal;
  font-style: normal; }

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ic-arrow-right:before {
  content: ""; }

.ic-close:before {
  content: ""; }

.ic-play:before {
  content: ""; }

.ic-scroll:before {
  content: ""; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 321px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1441px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Signika", sans-serif;
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5;
  color: #001b30;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong,
.link--primary,
.date__month,
.nav__toggle {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .date__day, .date__year, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Signika", sans-serif;
  font-weight: 600;
  line-height: 1.09;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3, .date__day, .date__year {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.75rem; }

h6, .h6 {
  font-size: 0.5rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.09; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.09; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.09; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.09; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 100; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.container {
  width: 100%;
  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1020px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1080px; } }
  @media (min-width: 1441px) {
    .container {
      max-width: 64.58333vw; } }

.container-fluid {
  width: 100%;
  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.5625vw;
  margin-left: -1.5625vw; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1.5625vw;
  padding-left: 1.5625vw; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 321px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 13; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1441px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.clearfix::after, .offers::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 321px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1441px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 321px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1441px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 321px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1441px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 321px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1441px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 100 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.animated {
  animation-duration: 500ms;
  animation-fill-mode: both; }

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -ms-animation-duration: 500ms;
  -o-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }

.bl {
  display: block; }

.in-bl {
  display: inline-block; }

.flex {
  display: flex; }

.flex-center {
  align-items: center;
  justify-content: center; }

.flex-space-between {
  justify-content: space-between; }

.word-break {
  word-break: break-all; }

.no-break {
  white-space: nowrap; }

.justify {
  text-align: justify; }

.flex-equal {
  flex: 1; }

.p-abs-center, .section--aktuality #toggleActivity div:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.screen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.no-scroll {
  margin: 0;
  height: 100vh;
  overflow: hidden; }

.no-events {
  pointer-events: none !important; }

.hidden {
  display: none; }

.hide, body.newsShown .section--aktuality #toggleActivity div:last-child, .news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
  opacity: 0 !important;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.show, .section--aktuality #toggleActivity div:last-child, .nav__toggle.is-active + .nav__list, body.newsShown .news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
  opacity: 1 !important;
  pointer-events: all;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.collapse {
  display: block;
  max-height: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none; }
  .collapse.show, .section--aktuality #toggleActivity div.collapse:last-child, .nav__toggle.is-active + .collapse.nav__list, body.newsShown .collapse.news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    max-height: 10000vh;
    opacity: 1;
    pointer-events: initial; }

@media (max-width: 991.98px) {
  .hidden-md {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hidden-sm {
    display: none !important; } }

@media (max-width: 575.98px) {
  .hidden-xs {
    display: none !important; } }

.space-m-all-0 {
  margin: 0 !important; }

.space-m-all-20 {
  margin: 1em !important; }

.space-m-all-30 {
  margin: 1.5em !important; }

.space-m-all-40 {
  margin: 2em !important; }

.space-m-all-60 {
  margin: 3em !important; }

.space-m-all-80 {
  margin: 4em !important; }

.space-m-all-100 {
  margin: 5em !important; }

.space-p-all-0 {
  padding: 0 !important; }

.space-p-all-20 {
  padding: 1em !important; }

.space-p-all-30 {
  padding: 1.5em !important; }

.space-p-all-40 {
  padding: 2em !important; }

.space-p-all-60 {
  padding: 3em !important; }

.space-p-all-80 {
  padding: 4em !important; }

.space-p-all-100 {
  padding: 5em !important; }

.space-m-t-0 {
  margin-top: 0 !important; }

.space-m-t-20 {
  margin-top: 1em !important; }

.space-m-t-30 {
  margin-top: 1.5em !important; }

.space-m-t-40 {
  margin-top: 2em !important; }

.space-m-t-60 {
  margin-top: 3em !important; }

.space-m-t-80 {
  margin-top: 4em !important; }

.space-m-t-100 {
  margin-top: 5em !important; }

.space-m-b-0 {
  margin-bottom: 0 !important; }

.space-m-b-20 {
  margin-bottom: 1em !important; }

.space-m-b-30 {
  margin-bottom: 1.5em !important; }

.space-m-b-40 {
  margin-bottom: 2em !important; }

.space-m-b-60 {
  margin-bottom: 3em !important; }

.space-m-b-80 {
  margin-bottom: 4em !important; }

.space-m-b-100 {
  margin-bottom: 5em !important; }

.space-p-t-0 {
  padding-top: 0 !important; }

.space-p-t-20 {
  padding-top: 1em !important; }

.space-p-t-30 {
  padding-top: 1.5em !important; }

.space-p-t-40 {
  padding-top: 2em !important; }

.space-p-t-60 {
  padding-top: 3em !important; }

.space-p-t-80 {
  padding-top: 4em !important; }

.space-p-t-100 {
  padding-top: 5em !important; }

.space-p-b-0 {
  padding-bottom: 0 !important; }

.space-p-b-20 {
  padding-bottom: 1em !important; }

.space-p-b-30 {
  padding-bottom: 1.5em !important; }

.space-p-b-40 {
  padding-bottom: 2em !important; }

.space-p-b-60 {
  padding-bottom: 3em !important; }

.space-p-b-80 {
  padding-bottom: 4em !important; }

.space-p-b-100 {
  padding-bottom: 5em !important; }

.bg-black {
  background: #000;
  color: #ffffff; }

.bg-white {
  background: #fff; }

.bg-grey {
  background: #f0f2f6; }

.bg-primary {
  background: #f69d00 !important;
  color: #ffffff; }

.greyToWhiteFilter {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='brightness'><feColorMatrix type='matrix' values='1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0'/></filter></svg>#brightness");
  /* Firefox 3.5+ */
  filter: brightness(108.5%);
  /* Chrome 19+ & Safari 6+ */ }

.roundedBorder, .date, .box, .new__content, .jumbotron {
  border-radius: 1rem;
  border: 1px solid #e0e4ec; }

.gradientBottom, .section--co-delame, .section--kontakt, .section--odkazy {
  position: relative; }
  .gradientBottom:before, .section--co-delame:before, .section--kontakt:before, .section--odkazy:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22.5rem;
    background-image: linear-gradient(to top, #f0f2f6 0%, rgba(240, 242, 246, 0) 100%);
    z-index: 0; }

.subpage .teaser__inner,
.subpage .teaser__box {
  display: none; }

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; }

figure, img {
  margin: 0; }

h1, .h1, h2, .h2, h3, .h3, .date__day, .date__year, h4, .h4, h5, .h5, h6, .h6 {
  text-align: center;
  margin: 1em 0 2em; }
  .section > h1:first-child,
  .container > h1:first-child, .section > .h1:first-child,
  .container > .h1:first-child, .section > h2:first-child,
  .container > h2:first-child, .section > .h2:first-child,
  .container > .h2:first-child, .section > h3:first-child,
  .container > h3:first-child, .section > .h3:first-child, .section > .date__day:first-child, .section > .date__year:first-child,
  .container > .h3:first-child,
  .container > .date__day:first-child,
  .container > .date__year:first-child, .section > h4:first-child,
  .container > h4:first-child, .section > .h4:first-child,
  .container > .h4:first-child, .section > h5:first-child,
  .container > h5:first-child, .section > .h5:first-child,
  .container > .h5:first-child, .section > h6:first-child,
  .container > h6:first-child, .section > .h6:first-child,
  .container > .h6:first-child {
    margin-top: 0; }

p {
  margin-bottom: 0.66667em; }
  p:last-child {
    margin-bottom: 0; }

.text, .btn, button {
  font-size: 1rem; }

.text--uppercase {
  text-transform: uppercase; }

.text--normal, .offers__title {
  font-weight: 100; }

.text--bold, h1, .h1, h2, .h2, h3, .h3, .date__day, .date__year, h4, .h4, h5, .h5, h6, .h6, .btn, .link--fb, .nav__item a, .more, b {
  font-weight: 600; }


strong,
.link--primary,
.date__month,
.nav__toggle {
  font-weight: 600; }

.text--extra-small {
  font-size: 0.6rem;
  line-height: 1;
  margin-bottom: 0.66667em; }

.text--small, .partners__label {
  font-size: 0.65rem; }

.text--semi-small, .footer__copyright, .date__month, .nav__toggle {
  font-size: 0.8rem; }

.text--semisemi-small {
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.25; }

.text--underline {
  position: relative; }
  .text--underline:after {
    content: '';
    position: absolute;
    bottom: -0.61538em;
    left: 50%;
    transform: translate(-50%, 0);
    height: 0.23077em;
    width: 100%;
    background: #001b30; }

.text--primary, .link--primary, .nav__item.active, .nav__item:hover, .nav__item:focus, .nav__item:active, .text--primary a, .link--primary a, .nav__item.active a, .nav__item:hover a, .nav__item:focus a, .nav__item:active a {
  color: #f69d00; }
  .text--primary:hover, .link--primary:hover, .nav__item:hover, .text--primary:focus, .link--primary:focus, .nav__item:focus, .text--primary a:hover, .link--primary a:hover, .nav__item.active a:hover, .nav__item:hover a:hover, .nav__item:focus a:hover, .nav__item:active a:hover, .text--primary a:focus, .link--primary a:focus, .nav__item.active a:focus, .nav__item:hover a:focus, .nav__item:focus a:focus, .nav__item:active a:focus {
    color: rgba(246, 157, 0, 0.75); }
  .text--primary:before, .link--primary:before, .nav__item.active:before, .nav__item:hover:before, .nav__item:focus:before, .nav__item:active:before, .text--primary:after, .link--primary:after, .nav__item.active:after, .nav__item:hover:after, .nav__item:focus:after, .nav__item:active:after, .text--primary a:before, .link--primary a:before, .nav__item.active a:before, .nav__item:hover a:before, .nav__item:focus a:before, .nav__item:active a:before, .text--primary a:after, .link--primary a:after, .nav__item.active a:after, .nav__item:hover a:after, .nav__item:focus a:after, .nav__item:active a:after {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .text--primary:before, .link--primary:before, .nav__item.active:before, .nav__item:hover:before, .nav__item:focus:before, .nav__item:active:before, .text--primary a:before, .link--primary a:before, .nav__item.active a:before, .nav__item:hover a:before, .nav__item:focus a:before, .nav__item:active a:before {
    color: #f69d00; }
  .text--primary:after, .link--primary:after, .nav__item.active:after, .nav__item:hover:after, .nav__item:focus:after, .nav__item:active:after, .text--primary a:after, .link--primary a:after, .nav__item.active a:after, .nav__item:hover a:after, .nav__item:focus a:after, .nav__item:active a:after {
    background: #f69d00; }

.text--primary.text--no-hover, .text--no-hover.link--primary, .text--no-hover.nav__item.active, .text--no-hover.nav__item:hover, .text--no-hover.nav__item:focus, .text--no-hover.nav__item:active, .text--primary.text--no-hover:hover, .text--no-hover.link--primary:hover, .text--no-hover.nav__item:hover, .text--primary.text--no-hover:focus, .text--no-hover.link--primary:focus, .text--no-hover.nav__item:focus {
  color: #f69d00 !important; }

.text--primary.text--underline:after, .text--underline.link--primary:after, .text--underline.nav__item.active:after, .text--underline.nav__item:hover:after, .text--underline.nav__item:focus:after, .text--underline.nav__item:active:after {
  background: #f69d00; }

.text--primary.text--underline:hover:after, .text--underline.link--primary:hover:after, .text--underline.nav__item:hover:after {
  background: rgba(246, 157, 0, 0.75); }

.text--secondary, .text--secondary a {
  color: #17a9e1; }
  .text--secondary:hover, .text--secondary:focus, .text--secondary a:hover, .text--secondary a:focus {
    color: rgba(23, 169, 225, 0.75); }
  .text--secondary:before, .text--secondary:after, .text--secondary a:before, .text--secondary a:after {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .text--secondary:before, .text--secondary a:before {
    color: #17a9e1; }
  .text--secondary:after, .text--secondary a:after {
    background: #17a9e1; }

.text--secondary.text--no-hover, .text--secondary.text--no-hover:hover, .text--secondary.text--no-hover:focus {
  color: #17a9e1 !important; }

.text--secondary.text--underline:after {
  background: #17a9e1; }

.text--secondary.text--underline:hover:after {
  background: rgba(23, 169, 225, 0.75); }

.text--black, .text--black a {
  color: #000; }
  .text--black:hover, .text--black:focus, .text--black a:hover, .text--black a:focus {
    color: rgba(0, 0, 0, 0.75); }
  .text--black:before, .text--black:after, .text--black a:before, .text--black a:after {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .text--black:before, .text--black a:before {
    color: #000; }
  .text--black:after, .text--black a:after {
    background: #000; }

.text--black.text--no-hover, .text--black.text--no-hover:hover, .text--black.text--no-hover:focus {
  color: #000 !important; }

.text--black.text--underline:after {
  background: #000; }

.text--black.text--underline:hover:after {
  background: rgba(0, 0, 0, 0.75); }

.text--white, .link--white, .text--white a, .link--white a {
  color: #fff; }
  .text--white:hover, .link--white:hover, .text--white:hover:before, .link--white:hover:before, .text--white:hover:after, .link--white:hover:after, .text--white:focus, .link--white:focus, .text--white:focus:before, .link--white:focus:before, .text--white:focus:after, .link--white:focus:after, .text--white a:hover, .link--white a:hover, .text--white a:hover:before, .link--white a:hover:before, .text--white a:hover:after, .link--white a:hover:after, .text--white a:focus, .link--white a:focus, .text--white a:focus:before, .link--white a:focus:before, .text--white a:focus:after, .link--white a:focus:after {
    color: rgba(255, 255, 255, 0.75); }
  .text--white:before, .link--white:before, .text--white:after, .link--white:after, .text--white a:before, .link--white a:before, .text--white a:after, .link--white a:after {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .text--white:before, .link--white:before, .text--white a:before, .link--white a:before {
    color: #fff; }
  .text--white:after, .link--white:after, .text--white a:after, .link--white a:after {
    color: #fff;
    background: #fff; }

@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 2.11111rem; } }

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 1.85714rem; } }

.ic {
  position: relative;
  font-size: 1rem; }

.ic-fb {
  color: #3b5998 !important; }

.btn, button {
  display: inline-block;
  font-family: "Signika", sans-serif;
  color: #001b30;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transform-origin: center;
  border: 0;
  box-shadow: 0;
  outline: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: transparent;
  cursor: pointer; }

.btn {
  height: 3em;
  min-width: 12em;
  border-radius: 1.5em;
  padding: 0 2em;
  background: #f69d00;
  color: #ffffff; }
  .btn:hover, .btn:active, .btn.active {
    box-shadow: 0 0.5em 1.5em 0 rgba(246, 157, 0, 0.5); }

a {
  color: #001b30; }
  a, a * {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  a:hover, a:focus, a:active {
    text-decoration: none;
    color: rgba(0, 27, 48, 0.75); }
  a[href^="tel:"] {
    color: #001b30; }

.link {
  position: relative;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .link:hover, .link:focus, .link:active {
    text-decoration: none; }
  .link .ic {
    position: absolute;
    font-size: 1.2em;
    top: 50% !important;
    left: auto;
    right: auto;
    transform: translate(0.5em, -50%); }

.link--underline {
  position: relative;
  display: inline-block;
  line-height: 1.2;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  white-space: nowrap; }
  .link--underline:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: currentColor;
    opacity: .25;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .link--underline:hover:after, .link--underline:focus:after, .link--underline:active:after, .link--underline.active:after {
    opacity: 0; }

.link--fb {
  color: #3b5998; }
  .link--fb img {
    position: relative;
    top: -0.1rem;
    margin-right: .25rem; }
  .link--fb:hover {
    color: #2d4373; }

.list--no-style, .list--custom, .list--counter, .list--checkers, .nav__list, .partners {
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin: 0; }

.list--inline li, .nav__list li, .list--inline .list__item, .nav__list .list__item {
  display: inline-block; }

.list--block-inline::after {
  display: block;
  clear: both;
  content: ""; }

.list--block-inline li, .list--block-inline .list__item {
  display: block;
  float: left; }

.list--horizontal {
  display: flex; }
  .list--horizontal > * {
    flex: 1; }

.list--more-space li, .list--more-space .list__item {
  margin-bottom: 0.75em; }

.list--custom li, .list--counter li {
  padding-left: 1em;
  margin-bottom: .5em; }
  .list--custom li .list__symbol, .list--counter li .list__symbol {
    position: relative;
    color: #007bff; }
    .list--custom li .list__symbol:before, .list--counter li .list__symbol:before {
      content: '';
      position: absolute;
      height: .5em;
      width: .5em;
      left: -1em;
      top: .33em;
      background-color: currentColor;
      color: currentColor;
      border-radius: 50%; }

.list--counter li {
  padding-left: 0;
  margin-bottom: 4em;
  counter-increment: step-counter;
  padding-left: 7.5em; }
  .list--counter li .list__symbol::before {
    font-size: 6em;
    line-height: 1;
    left: -0.25em;
    content: counter(step-counter) ".";
    font-weight: 300;
    height: auto;
    width: auto;
    background: none;
    top: 0;
    transform: translate(-100%, -37%); }

.list--checkers {
  margin-left: 1.7em; }
  .list--checkers .list__item {
    position: relative;
    margin-bottom: 2.5em;
    padding-left: 4em; }
    .list--checkers .list__item:last-child {
      margin-bottom: 0; }
    .list--checkers .list__item:before, .list--checkers .list__item:after {
      content: '';
      position: absolute; }
    .list--checkers .list__item:before {
      width: 2em;
      height: 2em;
      border-radius: 0.5em;
      box-shadow: 0 0.25em 0.75em 0 rgba(24, 63, 100, 0.15);
      left: 0.7em;
      top: 0.25em; }
    .list--checkers .list__item:after {
      width: 0.9em;
      height: 0.7em;
      background: url(../images/check.svg);
      background-size: cover;
      background-position: center;
      top: 1.25em;
      left: 1.7em;
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    .list--checkers .list__item.disabled:after {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.list__item--no-checkbox:before, .list__item--no-checkbox:after {
  display: none !important; }

.list__item--oneLine {
  padding-top: 0.55em; }

html, body {
  min-height: 100vh; }

html {
  font-size: 1.04167vw; }
  @media (min-width: 1921px) {
    html {
      font-size: 25px; } }
  @media (max-width: 1440.98px) {
    html {
      font-size: 1.31772vw; } }
  @media (max-width: 1199.98px) {
    html {
      font-size: 1.5625vw; } }
  @media (max-width: 991.98px) {
    html {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    html {
      font-size: 14px; } }

body {
  position: relative;
  background-color: #ffffff; }

.body-inner {
  overflow: hidden;
  margin-top: 5rem; }
  .body-inner:before {
    content: '';
    position: fixed;
    top: 5rem;
    width: calc(100% - 6rem);
    height: 0;
    padding-bottom: 36.5%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../images/teaser.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .body-inner:before {
        width: 100%;
        padding-bottom: 38.88889%; } }
    @media (max-width: 767.98px) {
      .body-inner:before {
        top: 3.57143rem;
        padding-bottom: 55%; } }
  @media (max-width: 767.98px) {
    .body-inner {
      margin-top: 3.57143rem; } }

.container {
  padding: 0 1em; }

*:not(img), *:before:not(img), *:after:not(img) {
  backface-visibility: hidden; }

*:not(input):focus {
  outline: none; }

.main-content {
  overflow: hidden;
  background: #fff; }

.header {
  padding: 0 3rem; }
  @media (max-width: 1199.98px) {
    .header {
      padding: 0; } }

.header__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  padding: 1rem 3rem 1rem 16.65rem;
  background: #fff;
  z-index: 10; }
  @media (min-width: 768px) {
    .header__nav.nav--scrolled {
      height: 3.5rem !important; }
      .header__nav.nav--scrolled .nav__logo {
        transform: scale(0.8); } }
  @media (max-width: 1199.98px) {
    .header__nav {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (max-width: 991.98px) {
    .header__nav .nav__item {
      font-size: 0.77778rem; } }
  @media (max-width: 767.98px) {
    .header__nav {
      height: 3.57143rem; } }

.header__teaser {
  height: 0;
  width: 100%;
  padding-bottom: 38.88889%; }
  @media (max-width: 767.98px) {
    .header__teaser {
      padding-bottom: 55%; } }

.subpage .header__teaser {
  padding-bottom: 350px; }
  @media (max-width: 1199.98px) {
    .subpage .header__teaser {
      padding-bottom: 250px; } }
  @media (max-width: 767.98px) {
    .subpage .header__teaser {
      padding-bottom: 150px; } }

.footer {
  text-align: center;
  padding: 3.75rem 0 1.25rem; }

.footer__copyright p {
  margin: 0; }

.section {
  padding-top: 3.75rem;
  padding-bottom: 4rem;
  position: relative; }
  .section:first-child {
    padding-top: 7.5rem; }
  @media (max-width: 991.98px) {
    .section {
      padding: 1.5rem 0; } }

.section--kdo-jsme .text-center {
  max-width: 27.5rem;
  margin: 3.5rem auto 0; }

.section--kdo-jsme img {
  width: 27.5rem;
  max-width: none;
  max-height: none; }

@media (max-width: 991.98px) {
  .section--kdo-jsme figure {
    margin-bottom: 3rem; }
  .section--kdo-jsme .list__item {
    padding-right: 4em; } }

@media (max-width: 767.98px) {
  .section--kdo-jsme img {
    width: 100%; } }

@media (max-width: 575.98px) {
  .section--kdo-jsme .list {
    margin-left: 0; }
  .section--kdo-jsme .list__item {
    padding-right: 0; } }

.section--aktuality {
  padding-top: 0;
  padding-bottom: 7.75rem; }
  .section--aktuality #toggleActivity {
    position: relative;
    overflow: visible;
    width: 100%;
    padding: 1rem 0; }
    .section--aktuality #toggleActivity div {
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
      .section--aktuality #toggleActivity div:last-child {
        background: #f0f2f6; }
    @media (max-width: 575.98px) {
      .section--aktuality #toggleActivity {
        font-size: 0.85714rem; } }

.section--kontakt {
  padding-top: 0;
  padding-bottom: 12.5rem; }
  .section--kontakt .teaser {
    margin-bottom: 5.75rem; }
  .section--kontakt .medailon {
    width: 5.5rem;
    height: 5.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem; }

.section--chat {
  padding-top: 0;
  padding-bottom: 0; }

.section--odkazy {
  padding-top: 0; }

.section--podporuji-nas {
  padding-top: 0; }

.subpage .section:first-child {
  padding-top: 3rem; }

.section--content h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem; }

.section--content a {
  color: #f69d00; }
  .section--content a:hover {
    color: rgba(246, 157, 0, 0.75); }

.section--content p,
.section--content ul {
  margin-bottom: 1.5rem;
  max-width: 35.5rem;
  margin-left: auto;
  margin-right: auto; }

.section--content b {
  font-weight: 600; }

.date {
  width: 4rem;
  height: 4rem;
  text-align: center;
  padding: 0.8rem 0; }
  .date > * {
    display: block;
    line-height: 1;
    margin: 0; }

.date__day, .date__year {
  color: #17a9e1; }

.date__year {
  font-size: 1.4rem;
  margin-top: 0.14286rem; }

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.nav--scrolled {
  box-shadow: 0 0 1rem 0 rgba(24, 63, 100, 0.15); }

.nav__logo {
  transform-origin: center; }
  .nav__logo img {
    width: 4.5rem;
    height: 2.7rem; }

@media (max-width: 767.98px) {
  .nav__list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    background: #ffffff;
    padding: .5rem;
    height: calc(100vh - 3.57143rem);
    transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .nav__list .nav__item {
      font-size: 1rem;
      padding: .25em; }
      .nav__list .nav__item:before {
        display: none; } }

@media (orientation: landscape) and (max-width: 767px) {
  .nav__list .nav__item, .nav__list .nav__item a {
    padding: 0; } }

.nav__item {
  text-align: center;
  margin: 0.5em 0.5em; }
  .nav__item a {
    display: block;
    padding: 0.25em; }

.nav__item--separated {
  position: relative;
  padding-left: 1.25rem; }
  .nav__item--separated:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 4rem;
    background-image: linear-gradient(to top, #ffffff 0%, #ffffff 15%, #e0e4ec 50%, #ffffff 85%);
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    @media (min-width: 768px) {
      .nav--scrolled .nav__item--separated:before {
        height: 2.8rem; } }

.nav__item--locale a {
  display: inline-block;
  font-weight: 100; }
  .nav__item--locale a.active {
    color: #f69d00; }

.nav__toggle {
  display: none;
  font-size: 18px;
  text-transform: uppercase;
  user-select: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 101;
  padding: 5px 8px 8px 20px;
  line-height: 1;
  background: transparent;
  color: #001b30;
  cursor: pointer; }
  .nav__toggle .nav__hamburger {
    position: absolute;
    top: 42%;
    left: 0;
    transform: translate(0, -50%);
    margin-top: 0;
    margin-bottom: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .nav__toggle .nav__hamburger, .nav__toggle .nav__hamburger:before, .nav__toggle .nav__hamburger:after {
      width: 13px;
      height: 2px;
      background-color: #001b30;
      border-radius: 0;
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
      transition-property: background-color, transform;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .nav__toggle .nav__hamburger:before, .nav__toggle .nav__hamburger:after {
      display: block;
      position: absolute;
      content: ""; }
    .nav__toggle .nav__hamburger:before {
      top: -5px; }
    .nav__toggle .nav__hamburger:after {
      top: 5px; }
  .nav__toggle .nav__text {
    display: inline-block; }
  .nav__toggle.is-active .nav__hamburger:before, .nav__toggle.is-active .nav__hamburger:after {
    background-color: #001b30; }
  .nav__toggle.is-active .nav__hamburger {
    background-color: transparent; }
  .nav__toggle.is-active .nav__hamburger:before {
    -webkit-transform: translateY(5px) rotate(45deg);
    -moz-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    -o-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg); }
  .nav__toggle.is-active .nav__hamburger:after {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -moz-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    -o-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg); }
  @media (max-width: 767.98px) {
    .nav__toggle {
      display: block; }
      .nav__toggle + .nav__list {
        opacity: 0;
        pointer-events: none; } }

.subpage .nav__list:not(.nav__list--homepage) {
  display: block !important; }

.subpage .nav__list--homepage {
  display: none; }

.teaser {
  position: relative;
  background-size: cover;
  background-position: center; }

.teaser__inner {
  position: absolute;
  top: 50%;
  left: 13.8rem;
  transform: translateY(-50%); }
  @media (max-width: 1199.98px) {
    .teaser__inner {
      left: 5rem;
      width: 100%;
      padding: 1rem; } }
  @media (max-width: 767.98px) {
    .teaser__inner {
      padding: 2rem;
      left: 0; } }
  @media (max-width: 359px) {
    .teaser__inner {
      padding: 1rem; } }

.teaser__title {
  text-align: left;
  margin: 0; }

.teaser__btn {
  margin-top: 2.5em; }

.teaser--kontakt {
  width: 100%;
  height: 0;
  padding-bottom: 20.83333%; }
  @media (max-width: 767.98px) {
    .teaser--kontakt {
      padding-bottom: 35%; } }

.offers__item {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  padding-bottom: 28.4375%;
  color: #ffffff;
  background-size: cover;
  background-position: center; }
  .offers__item:before, .offers__item:after {
    content: '';
    position: absolute;
    width: 110%;
    height: 1.25rem;
    background: #ffffff;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .offers__item:before {
    top: -0.1rem; }
  .offers__item:after {
    bottom: -0.1rem; }
  .offers__item:hover:before, .offers__item:hover:after {
    height: 0; }
  @media (max-width: 991.98px) {
    .offers__item {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      padding-bottom: 42.65625%; } }
  @media (max-width: 767.98px) {
    .offers__item {
      width: 100%;
      padding-bottom: 56.875%; } }

.offers__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left; }
  .offers__text p {
    margin-bottom: 1.5em; }
  .offers__text * {
    color: #ffffff !important; }
  @media (max-width: 767.98px) {
    .offers__text br {
      display: none; }
    .offers__text p, .offers__text .more {
      font-size: 0.85714rem; }
    .offers__text p {
      margin-bottom: .5rem; }
    .offers__text .offers__title {
      margin-bottom: 1rem; } }

.offers__title {
  text-align: left;
  margin: 0 0 1em; }

.offers__item--left:before, .offers__item--left:after {
  right: 0; }

.offers__item--left .offers__text {
  width: 20rem;
  right: 6.7rem; }

@media (max-width: 767.98px) {
  .offers__item--left .offers__text {
    width: 90%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%); } }

.offers__item--right:before, .offers__item--right:after {
  left: 0; }

.offers__item--right .offers__text {
  width: 21rem;
  left: 5.3rem; }

@media (max-width: 767.98px) {
  .offers__item--right .offers__text {
    width: 90%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%); } }

.more {
  display: inline-block;
  color: #f69d00; }
  .more:after {
    content: '';
    position: relative;
    display: inline-block;
    width: 0.7em;
    height: 0.5em;
    background: url(../images/arrow-right.svg);
    background-size: cover;
    margin-left: 0.3em;
    top: -0.05em; }

.more--white, .offers__text .more {
  color: #fff; }
  .more--white:after, .offers__text .more:after {
    background-image: url(../images/arrow-right-white.svg); }

.boxes {
  display: flex;
  justify-content: center;
  margin: 7.5rem 0; }
  @media (max-width: 991.98px) {
    .boxes {
      flex-direction: column;
      align-items: center; } }

.box, .new__content {
  position: relative;
  display: block;
  margin: 2rem;
  text-align: center;
  padding: 2.65rem 1.5rem 3rem;
  width: 16rem;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .box:hover, .new__content:hover {
    box-shadow: 0 1rem 3.75rem 0 rgba(24, 63, 100, 0.15);
    background: #ffffff;
    border-color: #ffffff; }
    .box:hover .box__img img:last-child, .new__content:hover .box__img img:last-child {
      opacity: 1; }

.box__img {
  position: relative;
  height: 3.3rem;
  margin-bottom: 3.25rem; }
  .box__img img {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    .box__img img:last-child {
      opacity: 0; }

.box--more {
  padding: 4.5rem 2.5rem 6.5rem; }
  .box--more .h4 {
    margin-bottom: 1.25em; }
  .box--more .more {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%); }

.news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
  max-height: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  body.newsShown .news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    max-height: 100vh; }
  body:not(.newsShown) .news__item:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    padding-top: 0;
    padding-bottom: 0; }

body:not(.newsShown) .news__item:nth-of-type(3) {
  border-color: transparent; }

.new {
  position: relative;
  padding: 1.25rem 6.05rem; }
  .new:not(:last-child) {
    border-bottom: 1px solid #e0e4ec; }
  @media (max-width: 767.98px) {
    .new {
      padding-right: 1rem; } }

.new__date {
  position: absolute;
  left: 1.05rem;
  top: 3.4rem; }

.new__content {
  width: 100%;
  padding: 2rem;
  text-align: left;
  margin: 0;
  border: 0; }
  .new__content .more {
    margin-top: 1.25em;
    transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  @media (max-width: 767.98px) {
    .new__content {
      padding: 1.07143rem; } }

.new__title {
  margin: 0 0 1.1em;
  color: #17a9e1;
  text-align: left; }

.new--collapse {
  cursor: pointer; }
  .new--collapse.active .more {
    opacity: 0; }

.new__collapse {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .new--collapse.active .new__collapse {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all; }

.jumbotron {
  position: relative;
  border: 0;
  text-align: center;
  background-color: #f0f2f6;
  padding: 1.4em 6.75em;
  max-width: 40em;
  margin: 0 auto;
  z-index: 1; }
  @media (max-width: 767.98px) {
    .jumbotron {
      padding: 1.5em; } }

.partners {
  text-align: center; }

.partners__item {
  vertical-align: top;
  text-align: center;
  margin: 0 1.25rem 2.5rem;
  display: inline-block; }
  .partners__item > * {
    display: block; }
  .partners__item img {
    height: 3.7rem;
    width: auto;
    margin: auto;
    margin-bottom: 1.1rem; }
  @media (max-width: 991.98px) {
    .partners__item {
      display: block;
      margin-left: auto;
      margin-right: auto; } }

.partners__item--dial-telecom img {
  position: relative;
  left: -0.5rem; }

.partners__item--svet-v-bezpeci img {
  position: relative;
  top: 0.4rem; }
  @media (max-width: 991.98px) {
    .partners__item--svet-v-bezpeci img {
      top: auto; } }

.partners__item--bakalari img {
  position: relative;
  left: -0.9rem; }

.partners__label {
  height: 2.75em;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .partners__label {
      height: auto; } }

.partners--small .partners__item {
  margin-left: 2rem;
  margin-right: 2rem; }
  .partners--small .partners__item img {
    height: 2.5rem; }

.banners {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .banners {
      flex-direction: column; } }

.banners__item {
  margin: 1rem 2rem; }
