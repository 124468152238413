
html, body {
  min-height: 100vh;
}

// rem responsive
html {

  // @extend .hide;

  // &.wf-active {
  //   @extend .show;
  // }

  font-size: vw($root-font-size * 1px);

  @media (min-width: 1921px) {
    font-size: ($root-font-size * 1px * 1.25);
  }

  @include media-breakpoint-down(xl) {
    font-size: vw(18px, 1366px);
  }

  @include media-breakpoint-down(lg) {
    font-size: vw(16px, 1024px);
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

body {
  position: relative;
  background-color: $text-color-inverse;
}

.body-inner {
  overflow: hidden;
  margin-top: rem(100);

  // background fixed
  &:before {

    $w: 1800;
    $h: 700;

    content: '';
    position: fixed;
    top: rem(100);
    width: calc(100% - #{rem(60 * 2)});
    height: 0;
    padding-bottom: 36.5%;

    left: 50%;
    transform: translateX(-50%);

    background-image: url(../images/teaser.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    z-index: -1;

    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-bottom: ($h / $w *100%);
    }

    @include media-breakpoint-down(sm) {
      top: rem(50, 14);
      padding-bottom: 55%;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: rem(50, 14);
  }
}

.container {
  padding: 0 1em;
}

// transition little move issue
// https://stackoverflow.com/questions/12980153/image-moves-on-hover-chrome-opacity-issue
*, *:before, *:after {
  &:not(img) {
    backface-visibility: hidden;
  }
}

*:not(input) {
  &:focus { outline: none; }
}

.main-content {
  overflow: hidden;
  background: #fff;
}
