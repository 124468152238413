@import "variables";

@font-face {
  font-family: '#{$iconfont-name}';
  src:  url('#{$icomoon-font-path}/#{$iconfont-name}.eot?abwiif');
  src:  url('#{$icomoon-font-path}/#{$iconfont-name}.eot?abwiif#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$iconfont-name}.ttf?abwiif') format('truetype'),
    url('#{$icomoon-font-path}/#{$iconfont-name}.woff?abwiif') format('woff'),
    url('#{$icomoon-font-path}/#{$iconfont-name}.svg?abwiif#usm') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$iconfont-name}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-arrow-right {
  &:before {
    content: $ic-arrow-right;
  }
}
.ic-close {
  &:before {
    content: $ic-close;
  }
}
.ic-play {
  &:before {
    content: $ic-play;
  }
}
.ic-scroll {
  &:before {
    content: $ic-scroll;
  }
}

