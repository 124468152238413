@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {

  position: absolute;
  top: 42%;
  left: 0;
  transform: translate(0, -50%);

  // margin-top: $height + $gutter;
  // margin-bottom: $height + $gutter;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &,
  &:before,
  &:after {
    // display: block;
    width: $width;
    height: $height;
    background-color: $color;
    @include border-radius($border-radius);
    -webkit-transition-property: background-color, -webkit-transform;
    -moz-transition-property: background-color, -moz-transform;
    -o-transition-property: background-color, -o-transform;
    transition-property: background-color, transform;

    -webkit-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    content: "";
  }

  &:before {
    top: -($height + $gutter);
  }

  &:after {
    top: $height + $gutter;
  }
}


// Burger animations
@mixin burger-to-cross($height: 5px, $gutter: 3px, $color: #000) {
  &:before,
  &:after {
    background-color: $color;
  }

  & {
    background-color: transparent;
  }

  &:before {
    -webkit-transform: translateY($gutter + $height) rotate(45deg);
    -moz-transform: translateY($gutter + $height) rotate(45deg);
    -ms-transform: translateY($gutter + $height) rotate(45deg);
    -o-transform: translateY($gutter + $height) rotate(45deg);
    transform: translateY($gutter + $height) rotate(45deg);
  }

  &:after {
    -webkit-transform: translateY(-($gutter + $height)) rotate(-45deg);
    -moz-transform: translateY(-($gutter + $height)) rotate(-45deg);
    -ms-transform: translateY(-($gutter + $height)) rotate(-45deg);
    -o-transform: translateY(-($gutter + $height)) rotate(-45deg);
    transform: translateY(-($gutter + $height)) rotate(-45deg);
  }
}


// Helper Mixins
@mixin border-radius($border-radius) {
  border-radius: $border-radius;
}