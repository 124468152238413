.section {
  padding-top: rem(75);
  padding-bottom: rem(80);
  position: relative;

  &:first-child {
    padding-top: rem(150);
  }

  @include media-breakpoint-down(md) {
    padding: rem(30) 0;
  }
}

.section--kdo-jsme {
  .text-center {
    max-width: rem(550);
    margin: rem(70) auto 0;
  }

  img {
    width: rem(550);
    max-width: none;
    max-height: none;
  }

  @include media-breakpoint-down(md) {

    figure {
      margin-bottom: 3rem;
    }

    .list__item {
      padding-right: em(80);
    }
  }

  @include media-breakpoint-down(sm) {
    img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    .list {
      margin-left: 0;
    }
    .list__item {
      padding-right: 0;
    }
  }
}

.section--co-delame {
  @extend .gradientBottom;
}

.section--aktuality {
  padding-top: 0;
  padding-bottom: rem(155);

  #toggleActivity {
    position: relative;
    overflow: visible;
    width: 100%;

    padding: 1rem 0;

    div {
      transition: $transition;

      &:last-child {
        @extend .p-abs-center;
        @extend .show;
        background: $bg-color;
      }
    }

    body.newsShown & {
      div {
        &:last-child {
          @extend .hide;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      font-size: rem(12, 14);
    }
  }
}

.section--kontakt {
  @extend .gradientBottom;
  padding-top: 0;
  padding-bottom: rem(250);
  .teaser {
    margin-bottom: rem(115);
  }

  .medailon {
    width: rem(110);
    height: rem(110);
    margin-top: rem(10);
    margin-bottom: rem(20);
  }
}

.section--chat {
  padding-top: 0;
  padding-bottom: 0;
}

.section--odkazy {
  @extend .gradientBottom;
  padding-top: 0;
}

.section--podporuji-nas {
  padding-top: 0;
}


.subpage {
  
  .section:first-child {
    padding-top: rem(60);
  }

}

.section--content {
  h1 {
    margin-bottom: rem(30);
    font-size: rem(40);
  }
  a {
    color: $primary-color;
    &:hover {
      color: rgba(246, 157, 0, 0.75);
    }
  }
  p,
  ul {
    margin-bottom: rem(30);
    max-width: rem(710);
    margin-left: auto;
    margin-right: auto;
  }
  b {
    font-weight: 600;
  }
}